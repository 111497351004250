<template>
  <!-- 排班设置 -->
  <div class="my_schedulingSettings">
    <van-nav-bar :title="$t('schedulingSettings.sesTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
    </van-nav-bar>
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="date_content">
      <div class="year_box">
        <div class="year_mon">
          <div class="icon_box"
               @click="arrowsYear('left')">
            <van-icon name="arrow-left" />
          </div>
          <div class="year_btn">
            <div class="year">{{ displayedDate }}</div>
          </div>
          <div class="icon_box"
               @click="arrowsYear('right')">
            <van-icon name="arrow" />
          </div>
        </div>
        <van-icon name="notes-o"
                  @click="showYearPicker = true" />
      </div>
      <div class="week_box">
        <div class="week_btn">
          <div class="week"
               :class="week.isActive ? 'underline-text' : ''"
               v-for="(week, w) in weeks"
               :key="w"
               @click="changeWeekOfMon(week)"
               v-show="week.dates.length>0">
            <div class="txt"> {{ week.name }} </div>
          </div>
        </div>
        <div class="date_box">
          <div class="txt">{{$t('schedulingSettings.sesTxt2')}}</div>
          <div class="date"
               :class="[da.checked?'date_active':'',!da.canCheck?'date_noCheck':'']"
               v-for="(da,dd) in checkedDates"
               :key="dd"
               @click="selectDate(da)">
            <div class="which_week">{{da.weekName}}</div>
            <div class="num">{{da.dayOfMonth}}</div>
          </div>
        </div>
        <div class="person_box">
          <van-checkbox-group v-model="checkedPerson"
                              ref="personCheckboxGroup">
            <div class="check_box"
                 v-for="(it,pp) in personList"
                 :key="pp">
              <van-checkbox :name="it"
                            @click="changeCheck(it)">
              </van-checkbox>
              <div class="empInfo">
                <div class="empname"
                     @click="changeBox(it,'',false,'')">{{it.empname}}</div>
                <div class="classes_box">
                  <div class="classes"
                       :class="[item['shiftautoid_w'+(te+1)] || item['shiftautoid_w'+(te+1)+'_name'] != '无'?'hasClasses':'', item.checking ?'has_Preselection':'',!item.canCheck?'no_click':'']"
                       v-for="(item,te) in it.personClasess"
                       :key="te"
                       @click="changeBox(it,te,true,item)">{{item['shiftautoid_w'+(te+1)+'_name']}} </div>
                  <div class="classes_line"></div>
                </div>
              </div>
            </div>

          </van-checkbox-group>
        </div>
      </div>
    </div>
    <div class="paging_box">
      <van-pagination v-model="currentPage"
                      :total-items="totalItems"
                      :items-per-page="pageSize"
                      :show-page-size="3"
                      force-ellipses
                      @change="changePage" />
    </div>
    <div class="btn_box">
      <van-checkbox v-model="checkedAll"
                    @click="checkAllPage">{{$t('module.Allsel')}}</van-checkbox>
      <div class="btn">
        <van-button type="info"
                    size="small"
                    @click="setShifts">{{$t('schedulingSettings.sesTxt3')}}</van-button>
        <!-- <van-button type="info"
                    size="small"
                    @click="submitShifts">{{$t('module.submit')}}</van-button> -->
      </div>
    </div>
    <!-- 选择年月 -->
    <van-popup v-model="showYearPicker"
               position="bottom"
               round>
      <van-datetime-picker v-model="yearMonth"
                           type="year-month"
                           :title="$t('schedulingSettings.sesTxt4')"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :formatter="formatter"
                           @confirm="onYearConfirm"
                           @cancel="showYearPicker = false" />
    </van-popup>

    <!-- 选择班次 -->
    <van-popup v-model="showShiftsPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :loading="loading"
                  :value-key="'str'"
                  :columns="shiftsList"
                  @confirm="onShiftsPickerConfirm"
                  @cancel="showShiftsPicker = false">
        <template #title>
          <van-search shape="round"
                      v-model="searchShift"
                      :placeholder="$t('module.PleaseEnterKeyWords')"
                      @input="searchShifts" />
        </template>
      </van-picker>
    </van-popup>

  </div>
</template>
<script>
import { parseTime } from '@/utils'
import { getWxMonthShiftInfo, saveWxMonthShiftInfo } from "@api/wx.js";
import { getCommTreeData } from "@api/wxjx.js";
import { Toast, Dialog } from 'vant';
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    return {
      userInfo,
      displayedDate: '',
      yearMonth: new Date(),
      showYearPicker: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      weeks: [
        {
          name: this.$t('schedulingSettings.sesTxt5'),
          dates: [],
          isActive: false
        },
        {
          name: this.$t('schedulingSettings.sesTxt6'),
          dates: [],
          isActive: false
        },
        {
          name: this.$t('schedulingSettings.sesTxt7'),
          dates: [],
          isActive: false
        },
        {
          name: this.$t('schedulingSettings.sesTxt8'),
          dates: [],
          isActive: false
        },
        {
          name: this.$t('schedulingSettings.sesTxt9'),
          dates: [],
          isActive: false
        },
        {
          name: this.$t('schedulingSettings.sesTxt10'),
          dates: [],
          isActive: false
        }
      ],
      bd: '',
      ed: '',
      checkedDates: [],
      personList: [],
      initPersonList: [],
      currentPage: 1,
      totalItems: 0,
      pageSize: 10,
      checkedPerson: [],
      checkedAll: false,
      showShiftsPicker: false,
      shiftsList: [],
      searchShift: '',
      loading: true
    }
  },
  watch: {
    // 班次弹窗
    showShiftsPicker (n) {
      if (n)
      {
        this.getCommTree();
      } else
      {
        this.loading = true
        this.searchShift = ''
      }
    },
    checkedDates (n) {
      let month = this.yearMonth.getMonth()
      n.forEach((el, id) => {
        el.dayOfMonth = el.date.getDate();
        if (el.date.getMonth() == month)
        {
          el.canCheck = true
        } else
        {
          el.canCheck = false
        }
      })
    }
  },
  mounted () {
    const year = new Date().getFullYear()
    const mon = new Date().getMonth() + 1
    this.displayedDate = year + this.$t('schedulingSettings.sesTxt11') + mon + this.$t('schedulingSettings.sesTxt12')
    this.initPageDatas(new Date(), true)
  },
  methods: {
    // checkbox点击时
    changeCheck (it) {
      const ids = this.checkedPerson.map(it => { return it.autoid })
      if (!ids.includes(it.autoid))
      {
        it.personClasess.forEach(el => {
          el.checking = false
        });
      }
      // else
      // {
      //   this.checkedDates.forEach((da, idx) => {
      //     if (da.checked)
      //     {
      //       it.personClasess[idx].checking = true
      //     }
      //   })
      // }
      this.$forceUpdate()
    },
    // 点击区域中圆点时
    changeBox (it, te, bo, item) {
      // 如果是不能选择的就return
      if (item && !item.canCheck)
      {
        return
      }
      // 选中对应的日期
      if (bo && this.checkedDates[te].canCheck)
      {
        // this.checkedDates[te].checked = true
        it.personClasess[te].checking = true
      }
      // 选中对应的人员
      const ids = this.checkedPerson.map(it => { return it.autoid })
      if (!ids.includes(it.autoid))
      {
        this.checkedPerson.push(it)
      }
      this.$forceUpdate()
    },
    // 全选
    checkAllPage () {
      if (this.checkedAll)
      {
        // this.$refs.personCheckboxGroup.toggleAll(true);
        this.personList.forEach(pe => {
          let bo = false
          for (let i = 0; i < this.checkedPerson.length; i++)
          {
            const el = this.checkedPerson[i];
            if (pe.autoid == el.autoid)
            {
              bo = true
              break;
            }
          }
          if (!bo)
          {
            this.checkedPerson.push(pe)
          }
        });
      } else
      {
        // this.$refs.personCheckboxGroup.toggleAll();
        const ids = this.personList.map(it => { return it.autoid })
        this.checkedPerson = this.checkedPerson.filter(it => {
          if (ids.includes(it.autoid))
          {
            // 清空掉他们的预选状态
            it.personClasess.forEach(el => {
              el.checking = false
            });
            return false
          } else
          {
            return true
          }
        })
      }
      // console.log(this.checkedPerson, 'this.checkedPerson');
    },
    // 左右切换月份
    arrowsYear (val) {
      let year = this.yearMonth.getFullYear()
      let month = this.yearMonth.getMonth()
      if (val == 'left')
      {
        month = month - 1
      } else
      {
        month = month + 1
      }
      this.yearMonth = new Date(year, month, 1);
      this.displayedDate = this.yearMonth.getFullYear() + this.$t('schedulingSettings.sesTxt11') + (this.yearMonth.getMonth() + 1) + this.$t('schedulingSettings.sesTxt12')
      this.initPageDatas(this.yearMonth, false)
    },
    // 选择年月
    onYearConfirm (value) {
      if (value)
      {
        this.yearMonth = value
        const year = this.yearMonth.getFullYear()
        const mon = this.yearMonth.getMonth() + 1
        this.displayedDate = year + this.$t('schedulingSettings.sesTxt11') + mon + this.$t('schedulingSettings.sesTxt12')
        this.initPageDatas(this.yearMonth, false)
      }
      this.showYearPicker = false
    },
    // 年月格式化
    formatter (type, val) {
      if (type === 'year')
      {
        return `${val}年`;
      } else if (type === 'month')
      {
        return `${val}月`;
      }
      return val;
    },
    // 提交班次
    submitShifts () {
      if (this.checkedPerson.length == 0)
      {
        Toast.fail(this.$t('schedulingSettings.sesTxt13'))
        return
      }
      if (this.checkedDates.filter(it => { return it.checked }).length == 0)
      {
        Toast.fail(this.$t('schedulingSettings.sesTxt14'))
        return
      }
      let data = []
      this.checkedPerson.forEach(it => {
        let obj = {}
        obj.autoid = it.autoid
        it.personClasess.forEach((pe, idx) => {
          obj['shiftautoid_w' + (idx + 1)] = pe['shiftautoid_w' + (idx + 1)]
        })
        data.push(obj)
      });
      const weeklist = this.checkedDates.filter(it => it.checked).map(item => item.num)
      let param = {
        username: this.userInfo.username,
        bd: parseTime(this.bd, '{y}-{m}-{d}'),
        ed: parseTime(this.ed, '{y}-{m}-{d}'),
        weeklist: weeklist.join(','),
        data: data,
      }
      // console.log(param, 'paramparamparamparam');
      saveWxMonthShiftInfo(param).then(res => {
        if (res.data && res.data[0].info)
        {
          Toast(res.data[0].info)
        } else
        {
          Toast.success(this.$t('module.submitScu'))
        }
      })
    },
    // 选择星期几
    selectDate (da) {
      if (!da.canCheck || this.checkedPerson.length == 0)
      {
        return
      }
      da.checked = !da.checked
      // 把选中人相对应日期的选中状态改变
      if (da.checked)
      {
        this.checkedPerson.forEach(it => {
          it.personClasess[da.num - 1].checking = true
        })
      } else
      {
        this.checkedPerson.forEach(it => {
          it.personClasess[da.num - 1].checking = false
        })
      }
      this.$forceUpdate()
    },
    // 搜索班次
    searchShifts () {
      if (this.searchShift)
      {
        this.shiftsList = this.shiftsList.filter(it => { return it.str.indexOf(this.searchShift) >= 0 })
      } else
      {
        this.getCommTree();
      }
    },
    // 选择班次确认回调
    onShiftsPickerConfirm (value) {
      Dialog.confirm({
        title: '提交',
        message: '确认保存该修改吗？',
      }).then(() => {
        console.log(this.checkedPerson, 'this.checkedPerson');
        let weeklist = []
        this.checkedPerson.forEach((it, idx) => {
          it.personClasess.forEach((item, id) => {
            if (item.checking)
            {
              if (value.autoid == -1)
              { // 清空选项
                item['shiftautoid_w' + (id + 1)] = -1
                item['shiftautoid_w' + (id + 1) + '_name'] = '无'
              } else
              {
                item['shiftautoid_w' + (id + 1)] = value.autoid
                item['shiftautoid_w' + (id + 1) + '_name'] = value.shiftname[0]
              }
              if (!weeklist.includes(id + 1))
              {
                weeklist.push(id + 1)
              }
            }
          });
        })
        this.$forceUpdate()
        this.showShiftsPicker = false
        // 提交数据
        let data = []
        this.checkedPerson.forEach(it => {
          let obj = {}
          obj.autoid = it.autoid
          it.personClasess.forEach((pe, idx) => {
            obj['shiftautoid_w' + (idx + 1)] = pe['shiftautoid_w' + (idx + 1)]
          })
          data.push(obj)
        });
        // const weeklist = this.checkedDates.filter(it => it.checked).map(item => item.num)
        let param = {
          username: this.userInfo.username,
          bd: parseTime(this.bd, '{y}-{m}-{d}'),
          ed: parseTime(this.ed, '{y}-{m}-{d}'),
          weeklist: weeklist.join(','),
          data: data,
        }
        // console.log(param, 'paramparamparamparam');
        // return
        saveWxMonthShiftInfo(param).then(res => {
          if (res.data && res.data[0].info)
          {
            Toast(res.data[0].info)
          } else
          {
            Toast.success(this.$t('module.submitScu'))
            let pagesize = window.pop_tree_pagesize ? window.pop_tree_pagesize : 10
            this.pageSize = Number(pagesize)
            this.getWxMonthShiftInfoData(this.bd, this.ed)
          }
        })

      }).catch(() => {

      });
    },
    // 设置排班按钮
    setShifts () {
      if (this.checkedPerson.length == 0)
      {
        Toast.fail(this.$t('schedulingSettings.sesTxt13'))
        return
      }
      // 判断只要有一个人选中了相对日期就通过
      let checkedDa = false
      for (let i = 0; i < this.checkedPerson.length; i++)
      {
        const el = this.checkedPerson[i];
        for (let p = 0; p < el.personClasess.length; p++)
        {
          const per = el.personClasess[p];
          if (per.checking)
          {
            checkedDa = true
            break
          }
        }
      }
      // if (this.checkedDates.filter(it => { return it.checked }).length == 0)
      if (!checkedDa)
      {
        Toast.fail(this.$t('schedulingSettings.sesTxt14'))
        return
      }
      this.showShiftsPicker = true
      // console.log(this.checkedPerson, 'checkedPerson', this.checkedDates)
    },
    // 获取班次
    getCommTree () {
      getCommTreeData({
        moduleno: 607,
        treename: 'baseshifttree', // 树名
        username: this.userInfo.username,
        fieldname: 'wx_month_shift',
        editstate: 3,
        formstr: "",
      }).then((res) => {
        this.shiftsList = res.data
        this.shiftsList.forEach(el => {
          el.str = el.shiftname + '|' + el.go1 + '-' + el.off1 + '|' + el.totalworktime
        });
        this.shiftsList.unshift({ autoid: -1, str: '<清空班次>' })
        this.loading = false
      })
    },
    // 切换这个月的第几周
    changeWeekOfMon (week) {
      // 获取当前日期所在周的开始和结束日期
      this.checkedDates = week.dates
      this.checkedDates.forEach((el, id) => {
        el.checked = false
      });
      this.weeks.forEach(el => {
        el.isActive = false
      });
      week.isActive = true
      this.bd = week.firstDay
      this.ed = week.lastDay
      let pagesize = window.pop_tree_pagesize ? window.pop_tree_pagesize : 10
      this.pageSize = Number(pagesize)
      this.getWxMonthShiftInfoData(this.bd, this.ed)
    },
    // 根据月份获取所在月的每周日期
    getAllDatesByMonth (val) {
      // 获取月份第一天 
      const date = new Date(val)
      const year = date.getFullYear()
      const month = date.getMonth()
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      this.weeks.forEach((el, idx) => {
        el.isActive = false // 先清除
        el.dates = []  // 日期先清空
        let days = null
        let other = ''
        let result = []
        if (idx == 0)
        {
          result = this.getDatesOfCurrentWeek(firstDay)
        } else
        {
          other = new Date(firstDay)
          days = other.getDate() + 7 * idx
          other.setDate(days); // 在原日期基础上增加天数  
          result = this.getDatesOfCurrentWeek(other)
        }
        // 如果周一属于下个月则清空
        if ((idx == 4 || idx == 5) && result[0].getMonth() != month)
        {
          el.dates = []
          el.firstDay = ''
          el.lastDay = ''
        } else 
        {
          result.forEach((re, idd) => {
            let obj = {}
            obj.weekName = idd == 0 ? this.$t('schedulingSettings.sesTxt15') : idd == 1 ? this.$t('schedulingSettings.sesTxt16') : idd == 2 ? this.$t('schedulingSettings.sesTxt17') : idd == 3 ? this.$t('schedulingSettings.sesTxt18') : idd == 4 ? this.$t('schedulingSettings.sesTxt19') : idd == 5 ? this.$t('schedulingSettings.sesTxt20') : idd == 6 ? this.$t('schedulingSettings.sesTxt21') : ''
            obj.date = re
            obj.whichDay = idd == 0 ? 'Mon' : idd == 1 ? 'Tue' : idd == 2 ? 'Wed' : idd == 3 ? 'Thu' : idd == 4 ? 'Fri' : idd == 5 ? 'Sat' : idd == 6 ? 'Sun' : ''
            obj.num = idd + 1
            el.dates.push(obj)
          })
          if (idx == 0)
          {
            el.firstDay = firstDay
          } else
          {
            el.firstDay = result[0]
          }
          if (result[result.length - 1].getMonth() != month)
          { // 如果本周最后一天不属于本月那就直接等于最后一天
            el.lastDay = lastDay
          } else
          { // 否则等于本周最后一天
            el.lastDay = result[result.length - 1]
          }
        }
      })
    },
    // 获取某个日期属于所在月的第几周
    getWeekOfMonth (date) {
      const today = new Date(date);
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const firstDayOfWeek = firstDayOfMonth.getDay() || 7; // 如果是周日，则默认为7 
      const daysSinceStartOfWeek = today.getDate() - firstDayOfMonth.getDate();
      const week = Math.ceil((daysSinceStartOfWeek + firstDayOfWeek) / 7);
      return week;
    },
    // 获取某个日期所在周的所有日期
    getDatesOfCurrentWeek (date) {
      const today = new Date(date);
      const dayOfWeek = today.getDay(); // 0 表示星期日，1 到 6 表示星期一到星期六
      const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - daysToSubtract);
      const dates = [];
      for (let i = 0; i < 7; i++)
      {
        dates.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }
      return dates;
    },
    // 获取某段时间所有人员的排班记录接口
    getWxMonthShiftInfoData (bd, ed) {
      this.checkedAll = false
      this.checkedPerson = []
      getWxMonthShiftInfo({
        bd: parseTime(bd, '{y}-{m}-{d}'),
        ed: parseTime(ed, '{y}-{m}-{d}'),
        username: this.userInfo.username
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        this.initPersonList = result
        this.initPersonList.forEach(item => {
          item.personClasess = [
            { shiftautoid_w1: 0, shiftautoid_w1_name: '无', checking: false, canCheck: this.checkedDates[0].canCheck },
            { shiftautoid_w2: 0, shiftautoid_w2_name: '无', checking: false, canCheck: this.checkedDates[1].canCheck },
            { shiftautoid_w3: 0, shiftautoid_w3_name: '无', checking: false, canCheck: this.checkedDates[2].canCheck },
            { shiftautoid_w4: 0, shiftautoid_w4_name: '无', checking: false, canCheck: this.checkedDates[3].canCheck },
            { shiftautoid_w5: 0, shiftautoid_w5_name: '无', checking: false, canCheck: this.checkedDates[4].canCheck },
            { shiftautoid_w6: 0, shiftautoid_w6_name: '无', checking: false, canCheck: this.checkedDates[5].canCheck },
            { shiftautoid_w7: 0, shiftautoid_w7_name: '无', checking: false, canCheck: this.checkedDates[6].canCheck }
          ]
          // 将记录中的班次按照日期赋值入数组中
          let keys = Object.keys(item)
          for (let c = 0; c < this.checkedDates.length; c++)
          {
            const el = this.checkedDates[c]
            if (keys.includes('shiftname' + el.dayOfMonth))
            {
              item.personClasess[el.num - 1]['shiftautoid_w' + el.num + '_name'] = item['shiftname' + el.dayOfMonth] ? item['shiftname' + el.dayOfMonth][0] : '无'
            }
          }
        });
        this.totalItems = result.length
        this.currentPage = 1
        this.$nextTick(() => {
          this.personList = this.initPersonList.slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
          )
        })
      })
    },
    // 分页操作
    changePage () {
      this.personList = this.initPersonList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      )
      // 改变全选状态
      const ids = this.checkedPerson.map(it => { return it.autoid })
      let ary = this.personList.filter(it => {
        return !ids.includes(it.autoid)
      })
      this.checkedAll = ary.length == 0
    },
    // 初始化页面
    initPageDatas (date, isMounted) {
      let currentDate = null
      if (isMounted)
      {
        // 第一次进默认当前日期
        currentDate = date
      } else
      {
        // 否则是选择年月的第一天 
        const year = date.getFullYear()
        const month = date.getMonth()
        currentDate = new Date(year, month, 1);
      }
      // 根据月份获取所在月的每周日期
      this.getAllDatesByMonth(date)
      // 当前日期是第几周
      let week = this.getWeekOfMonth(currentDate)
      // 获取当前日期所在周的开始和结束日期
      this.checkedDates = this.weeks[week - 1].dates
      this.checkedDates.forEach((el, id) => {
        el.checked = false
      });
      // console.log(week, 'week', this.checkedDates);
      this.weeks[week - 1].isActive = true
      this.bd = this.weeks[week - 1].firstDay
      this.ed = this.weeks[week - 1].lastDay
      let pagesize = window.pop_tree_pagesize ? window.pop_tree_pagesize : 10
      this.pageSize = Number(pagesize)
      this.getWxMonthShiftInfoData(this.bd, this.ed)
    },
    // 返回
    onClickLeft () {
      if (this.$route.query.from == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/personCen')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.my_schedulingSettings {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  .van-nav-bar {
    background: #2b8df0;
    z-index: 99;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }

  .date_content {
    height: calc(100% - 120px - 1.22667rem - 110px);
    .year_box {
      display: flex;
      align-items: center;
      height: 120px;
      padding: 20px 0;
      // background: #ffffff;
      .year_mon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        .icon_box {
          display: flex;
          align-items: center;
          .van-icon {
            font-size: 40px;
            font-weight: bold;
          }
        }
        .year_btn {
          width: calc((100% - 40px) / 3);
          color: #2a2a2a;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          .year {
            padding: 10px 0;
          }
        }
      }
      .van-icon-notes-o {
        font-size: 40px;
        font-weight: bold;
        margin-right: 30px;
      }
    }
    .week_box {
      height: calc(100% - 120px);
      .week_btn {
        height: 60px;
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        .week {
          // color: #2a2a2a;
          color: #333333;
        }
        .underline-text {
          // text-decoration: underline;
          color: #2b8df0 !important;
          position: relative;
        }
        .underline-text::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background: #2b8df0; /* 下划线颜色 */
          width: 100%;
        }
        .txt {
          font-size: 32px;
          font-weight: bold;
        }
      }
      .date_box {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #fff;
        padding: 20px 0;
        border-radius: 40px 40px 0 0;
        .txt {
          text-align: center;
          width: 160px;
          font-size: 28px;
        }
        .date {
          text-align: center;
          padding: 10px;
          border-radius: 16px;
          border: 1px solid #333333;
          .which_week {
            font-size: 24px;
            color: #333333;
          }
          .num {
            font-size: 28px;
            font-weight: 600;
          }
        }
        .date_active {
          color: #54a4ec;
          background: #ddedfb;
          border-radius: 16px;
          border: 1px solid #54a4ec;
          .which_week {
            color: #54a4ec;
          }
        }
        .date_noCheck {
          color: #dddddd;
          border: 1px solid #dddddd;
          .which_week {
            color: #dddddd;
          }
        }
      }
      .person_box {
        height: calc(100% - 150px - 60px);
        background: #fff;
        .van-checkbox-group {
          height: 100%;
          overflow-y: auto;
          .check_box {
            display: flex;
            padding: 10px;
            .van-checkbox {
              /deep/.van-checkbox__icon {
                height: auto;
                font-size: 24px;
                .van-icon {
                  font-size: 24px;
                }
              }
              /deep/.van-checkbox__label {
              }
            }
            .empInfo {
              display: flex;
              align-items: center;
              background: #eef5fc;
              padding: 20px 10px;
              border-radius: 20px;
              margin-left: 6px;
              flex: 1;
              .empname {
                width: 120px;
                font-size: 28px;
              }
              .classes_box {
                flex: 1;
                display: flex;
                justify-content: space-around;
                position: relative;
                .classes {
                  width: 50px;
                  height: 50px;
                  line-height: 50px;
                  border-radius: 50%;
                  background: #dddddd;
                  text-align: center;
                  font-size: 28px;
                  z-index: 9;
                  border: 1px solid #dddddd;
                }
                .hasClasses {
                  background: #69dd66;
                  color: #ffffff;
                }
                .has_Preselection {
                  background: #b6ddff !important;
                }
                .no_click {
                  color: #dddddd;
                  background: #ffffff;
                }
              }
              .classes_line {
                width: 100%;
                position: absolute;
                top: 50%;
                pointer-events: none; /* 防止伪元素影响鼠标事件 */
                border: 1px dashed #ced5db; /* 虚线边框 */
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

  .paging_box {
    height: 120px;
    display: flex;
    align-items: flex-end;
    .van-pagination {
      width: 100%;
    }
  }
  .btn_box {
    height: 110px;
    display: flex;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    .van-checkbox {
      font-size: 28px;
    }
    .btn {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex: 1;
      .van-button {
        width: 60%; // 40%;
        font-size: 32px;
        padding: 10px 0;
        border-radius: 20px;
      }
    }
  }
}
</style>